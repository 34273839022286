var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"invoice-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-select',{staticClass:"invoice-list-actions me-3",attrs:{"label":"Actions","single-line":"","outlined":"","dense":"","items":_vm.actions,"hide-details":"","disabled":Boolean(!_vm.selectedTableData.length)},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","to":{ name: 'apps-invoice-add' }}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_c('span',[_vm._v("Create Invoice")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-text-field',{staticClass:"invoice-list-search me-3",attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search Invoice"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-select',{staticClass:"invoice-list-status",attrs:{"items":_vm.statusOptions,"single-line":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":"Select Status"},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.invoiceListTable,"options":_vm.options,"server-items-length":_vm.totalInvoiceListTable,"loading":_vm.loading,"show-select":"","footer-props":{
      'items-per-page-text': _vm.$t('rowsPerPage'),
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.trending",fn:function(){return [_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{staticClass:"font-weight-medium text-decoration-none",attrs:{"to":{ name: 'apps-invoice-preview', params: { id: item.id } }}},[_vm._v(" #"+_vm._s(item.id)+" ")])]}},{key:"item.trending",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({class:("v-avatar-light-bg " + (_vm.resolveInvoiceStatusVariantAndIcon(item.invoiceStatus).variant) + "--text"),attrs:{"size":"30","color":_vm.resolveInvoiceStatusVariantAndIcon(item.invoiceStatus).variant}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveInvoiceStatusVariantAndIcon(item.invoiceStatus).variant}},[_vm._v(" "+_vm._s(_vm.resolveInvoiceStatusVariantAndIcon(item.invoiceStatus).icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.invoiceStatus))])])]}},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveClientAvatarVariant(item.invoiceStatus)) + "--text"),attrs:{"color":_vm.resolveClientAvatarVariant(item.invoiceStatus),"size":"30"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.client.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(item.client.name))]),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.client.companyEmail))])])],1)]}},{key:"item.total",fn:function(ref){
    var item = ref.item;
return [_vm._v(" $"+_vm._s(item.total)+" ")]}},{key:"item.balance",fn:function(ref){
    var item = ref.item;
return [(_vm.checkType(item.balance) !== 'number')?_c('span',[_vm._v(" "+_vm._s(item.balance)+" ")]):_c('v-chip',{class:("v-chip-light-bg " + (item.total !== item.balance ? 'success' : 'error') + "--text font-weight-semibold"),attrs:{"small":"","color":item.total !== item.balance ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item.total !== item.balance ? 'Paid' : 'Unpaid')+" ")])]}},{key:"item.issuedDate",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.issuedDate))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:('delete'),expression:"'delete'"}]})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":{ name: 'apps-invoice-preview', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Invoice")])]),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actionOptions),function(option,i){return _c('v-list-item',{key:i,attrs:{"href":"javascript:void(0)"}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(option.icon)+" ")]),_c('span',[_vm._v(_vm._s(option.title))])],1)],1)}),1)],1)],1)]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }